import { createSelector } from 'reselect';
import { State } from '../store';

const usersSelector = (state: State) => state.userStore.users;

const usersByRoleSelector = (role: string) => createSelector(
  usersSelector,
  (users) => users.filter((user) => user.roles.includes(role)),
);

const usersToModerateSelector = (role: string) => createSelector(
  usersSelector,
  (users) => users.filter((user) => user.roles.includes(role)
  && (!user.is_ban && !user.is_activated)),
);

const usersActiveSelector = (role: string) => createSelector(
  usersSelector,
  (users) => users.filter((user) => user.roles.includes(role)
  && !user.is_ban && user.is_activated),
);

const usersDisabledSelector = (role: string) => createSelector(
  usersSelector,
  (users) => users.filter((user) => user.roles.includes(role)
  && user.is_ban),
);

export {
  usersToModerateSelector, usersActiveSelector, usersDisabledSelector, usersByRoleSelector,
};
