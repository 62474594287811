import React from 'react';
import { Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import UsersMarketingTable from './UsersMarketingTable';
import { usersActiveSelector, usersDisabledSelector, usersToModerateSelector } from '../../../../store/user/selectors';

const UsersMarketingContent = () => {
  const { t } = useTranslation(['backoffice']);
  const usersToModerate = useSelector(usersToModerateSelector('ROLE_MARKETING'));
  const usersActive = useSelector(usersActiveSelector('ROLE_MARKETING'));
  const usersDisabled = useSelector(usersDisabledSelector('ROLE_MARKETING'));

  return (
    <div style={{ padding: '1rem 0' }}>
      <Typography variant="h4" style={{ margin: '1rem 0' }}>{t('users.subtitle.toModerate')}</Typography>
      <UsersMarketingTable users={usersToModerate} isModerate />
      <Typography variant="h4" style={{ margin: '1rem 0' }}>{t('users.subtitle.active')}</Typography>
      <UsersMarketingTable users={usersActive} isActive />
      <Typography variant="h4" style={{ margin: '1rem 0' }}>{t('users.subtitle.disabled')}</Typography>
      <UsersMarketingTable users={usersDisabled} isDisabled />
    </div>
  );
};

export default React.memo(UsersMarketingContent);
