import React from 'react';
import { Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import UsersOphthaTable from './UsersOphthaTable';
import { usersActiveSelector, usersDisabledSelector, usersToModerateSelector } from '../../../../store/user/selectors';

const UsersOphthaContent = () => {
  const { t } = useTranslation(['backoffice']);
  const usersToModerate = useSelector(usersToModerateSelector('ROLE_OPHTHA'));
  const usersActive = useSelector(usersActiveSelector('ROLE_OPHTHA'));
  const usersDisabled = useSelector(usersDisabledSelector('ROLE_OPHTHA'));

  return (
    <div style={{ padding: '1rem 0' }}>
      <Typography variant="h4" style={{ margin: '1rem 0' }}>
        {t('users.subtitle.toModerate')}
      </Typography>
      <UsersOphthaTable users={usersToModerate} isModerate />
      <Typography variant="h4" style={{ margin: '1rem 0' }}>
        {t('users.subtitle.active')}
      </Typography>
      <UsersOphthaTable users={usersActive} isActive />
      <Typography variant="h4" style={{ margin: '1rem 0' }}>
        {t('users.subtitle.disabled')}
      </Typography>
      <UsersOphthaTable users={usersDisabled} isDisabled />
    </div>
  );
};

export default React.memo(UsersOphthaContent);
