import axios from 'axios';
import {
  apiMarketingEmails, apiRefreshToken, apiUsers, apiAdmins,
} from '../constants/api';
/* eslint-disable import/no-cycle */
import { getToken } from '../services/AuthService';
import { IUser } from '../store/user/types';

export const apiPostRefresh = async (refresh: string) => {
  const data = await axios.post(
    `${process.env.REACT_APP_API_ROOT}${apiRefreshToken}`,
    { refresh_token: refresh },
  );
  return data;
};

export const apiGetUsers = async () => {
  const token: string = await getToken();
  const data = await axios.get(
    `${process.env.REACT_APP_API_ROOT}${apiUsers}`,
    { headers: { Authorization: `Bearer ${token}` } },
  );
  return data;
};

export const apiGetMarketingEmails = async () => {
  const token: string = await getToken();
  const data = await axios.get(
    `${process.env.REACT_APP_API_ROOT}${apiMarketingEmails}`,
    { headers: { Authorization: `Bearer ${token}` } },
  );
  return data;
};

export const apiPostMarketingEmails = async (email: string) => {
  const token: string = await getToken();
  const data = await axios.post(
    `${process.env.REACT_APP_API_ROOT}${apiMarketingEmails}`,
    {
      email,
    },
    { headers: { Authorization: `Bearer ${token}` } },
  );
  return data;
};

export const apiDeleteMarketingEmails = async (emailId: string) => {
  const token: string = await getToken();
  const data = await axios.delete(
    `${process.env.REACT_APP_API_ROOT}${apiMarketingEmails}/${emailId}`,
    { headers: { Authorization: `Bearer ${token}` } },
  );
  return data;
};

export const apiPostAdminUser = async (content: Partial<IUser>) => {
  const token: string = await getToken();
  const data = await axios.post(
    `${process.env.REACT_APP_API_ROOT}${apiAdmins}`,
    content,
    { headers: { Authorization: `Bearer ${token}` } },
  );
  return data;
};

export const apiPutUser = async (userId: string, content: Partial<IUser>) => {
  const token: string = await getToken();
  const data = await axios.put(
    `${process.env.REACT_APP_API_ROOT}${apiUsers}/${userId}`,
    content,
    { headers: { Authorization: `Bearer ${token}` } },
  );
  return data;
};

export const apiDeleteUser = async (userId: string) => {
  const token: string = await getToken();
  const data = await axios.delete(
    `${process.env.REACT_APP_API_ROOT}${apiUsers}/${userId}`,
    { headers: { Authorization: `Bearer ${token}` } },
  );
  return data;
};

export const apiExportUsers = async (role: 'ROLE_OPHTHA'|'ROLE_MARKETING'|'ROLE_ADMIN'|'') => {
  const token: string = await getToken();
  let roleParam: string = '';
  if (role && role.length > 0) {
    roleParam = `&role=${role}`;
  }
  const data = await axios.get(
    `${process.env.REACT_APP_API_ROOT}${apiUsers}?format=csv${roleParam}`,
    { headers: { Authorization: `Bearer ${token}` } },
  );
  return data;
};

export default {
  apiPostRefresh,
  apiGetUsers,
  apiPutUser,
  apiGetMarketingEmails,
  apiDeleteUser,
};
