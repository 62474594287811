import React, {
  Dispatch,
  FormEvent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  CircularProgress,
  Grid,
  Typography,
} from '@material-ui/core';
import {
  DefaultRootState,
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  useHistory,
  useParams,
} from 'react-router';
import useStyles from '../../../components/paper/MainPaper.style';
import WhitePaper from '../../../components/paper/WhitePaper';
import GreyTextInput from '../../../components/form/GreyTextInput';
import UploadFileButton from '../../../components/form/UploadFileButton';
import {
  getToolboxes,
  postToolbox,
  putToolbox,
  resetStatus,
} from '../../../store/toolbox/actions';
import {
  IToolbox,
  ToolboxState,
} from '../../../store/toolbox/types.d';
import { routeToolbox } from '../../../constants/routes';
import Breadcrumb from '../../../components/common/Breadcrumb';
import { NotificationContext } from '../../../contexts/NotificationContext';
import getFileName from '../../../utils/getFileName';
import { sortOrderExist } from '../../../utils/sortOrderExist';

type Props = {};

type FileTool = {
  file: File | string,
  fileName: string
}

const FormToolboxPage: React.FC<Props> = () => {
  const { t } = useTranslation(['backoffice', 'common']);
  const classes = useStyles();
  const history = useHistory();
  const dispatch: Dispatch<any> = useDispatch();
  const slug: { id: string } = useParams();
  const { setNotification } = useContext(NotificationContext);

  const {
    sending,
    toolboxes,
    error,
    status,
  } = useSelector<DefaultRootState, ToolboxState>(
    (state: any) => state.toolboxStore,
  );

  useEffect(() => {
    if (toolboxes.length === 0) dispatch(getToolboxes());
  }, []);

  const toolboxId = slug.id;
  let toolbox: IToolbox | undefined;

  if (toolboxId) {
    toolbox = toolboxes.find((tool: IToolbox) => tool.id === toolboxId);
  }

  const getValuesFromToolbox = (from: IToolbox | undefined) => ({
    title: from?.title ?? '',
    sort_order: from?.sort_order ?? 0,
  });

  const [values, setValues] = useState<Pick<IToolbox, 'title' | 'sort_order'>>(
    getValuesFromToolbox(toolbox),
  );

  const [formErrors, setFormErrors] = useState({
    title: '',
    sort_order: '',
    file: '',
  });

  const getFileFromToolbox = (from: IToolbox | undefined) => ({
    file: from?.file_path ?? '',
    fileName: getFileName(from?.file_path) ?? '',
  });

  const [file, setFile] = useState<FileTool>(
    getFileFromToolbox(toolbox),
  );

  useEffect(() => {
    toolbox = toolboxes.find((tool: IToolbox) => tool.id === toolboxId);
    setValues(getValuesFromToolbox(toolbox));
    setFile(getFileFromToolbox(toolbox));
  }, [toolboxes]);

  const handleFormAction = (event: FormEvent) => {
    event.preventDefault();
    event.stopPropagation();
    let formError = false;

    if (values.title === '') {
      formErrors.title = t('toolbox.form.titleRequired');
      formError = true;
    }

    if (file.file === '') {
      formErrors.file = t('toolbox.form.fileRequired');
      formError = true;
    }
    if (
      (toolbox?.sort_order !== values.sort_order)
      && sortOrderExist(toolboxes, values.sort_order)
    ) {
      formErrors.sort_order = t('toolbox.form.orderExist');
      formError = true;
    }

    setFormErrors({ ...formErrors });

    if (!formError) {
      if (toolboxId) {
        dispatch(putToolbox(toolboxId, values, file.file));
      } else {
        dispatch(postToolbox(values, file.file as File));
      }
    }
  };

  const handleChange = (prop: keyof Partial<IToolbox>, value: any) => {
    setValues({ ...values, [prop]: value });
    setFormErrors({ ...formErrors, [prop]: '' });
  };

  const uploadFile = (fileUploaded: File) => {
    if (file) {
      setFile({ file: fileUploaded, fileName: fileUploaded.name });
      setFormErrors({ ...formErrors, file: '' });
    }
  };

  useEffect(() => {
    if (status === 'ERROR') {
      setNotification({ isOpened: true, severity: 'error', message: error || '' });
    }

    if (status === 'SUCCESS') {
      setNotification({ isOpened: true, severity: 'success', message: t('toolbox.form.success') });
      history.push(routeToolbox);
    }
    if (status !== 'INIT') dispatch(resetStatus());
  }, [status]);

  return (
    <>
      <Breadcrumb
        title={t('title.toolbox')}
        baseUrl={routeToolbox}
        link={toolbox && toolbox.title}
      />
      <Typography variant="h1" className={classes.title}>
        {toolbox && toolbox.title}
      </Typography>
      <form onSubmit={handleFormAction}>
        <Grid container spacing={4}>
          <Grid item id="form-grid-item" xs={12} md={9}>
            <WhitePaper title={t('toolbox.form.subtitle')}>
              <GreyTextInput
                type="text"
                value={values.title}
                label={t('toolbox.form.title')}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange('title', event.target.value)}
                error={formErrors.title !== ''}
                helperText={formErrors.title}
              />
              <GreyTextInput
                type="number"
                value={values.sort_order}
                label={t('toolbox.form.sort_order')}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange('sort_order', Number(event.target.value))}
                error={formErrors.sort_order !== ''}
                helperText={formErrors.sort_order}
              />
              <Grid container direction="column" spacing={1}>
                <Grid item>
                  <UploadFileButton
                    uploadFile={uploadFile}
                    error={formErrors.file !== ''}
                    helperText={formErrors.file}
                    label={t('common.addFile')}
                    accept="application/pdf"
                  />
                </Grid>
                {file && file.fileName && (
                  <Grid item>
                    <Typography variant="body1">{t('toolbox.form.actualFile', { filename: file.fileName })}</Typography>
                  </Grid>
                )}
              </Grid>

            </WhitePaper>
            <br />
          </Grid>
          <Grid item id="validation-grid-item" xs={12} md={3}>
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              disabled={sending}
              fullWidth
            >
              <Typography variant="button">
                {t('common.save')}
              </Typography>
              {
                sending
                && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                    color="primary"
                  />
                )
              }
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default FormToolboxPage;
