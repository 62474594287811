import React, { Dispatch } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Grid, Paper, Popper, Typography, MenuList, MenuItem, ClickAwayListener, Fade, CardContent,
  Card,
  CardMedia,
  IconButton,
} from '@material-ui/core';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { format } from 'date-fns';
import fr from 'date-fns/locale/fr';
import useStyles from './EventItem.style';
import { eventCategories, IEvent } from '../../../store/event/types.d';
import { routeEvents } from '../../../constants/routes';
import image from '../../../images/background_placeholder_event.svg';
import { htmlToString } from '../../../utils/editorHelper';
import MoreVerticalOutline from '../../../components/icon/MoreVerticalOutline';

type Props = {
  event: IEvent,
  categories: eventCategories[],
  /* eslint-disable no-unused-vars */
  handleClickOpenModal: (id: string) => void
};

const EventItem = ({ event, categories, handleClickOpenModal } : Props) => {
  const { t } = useTranslation(['backoffice', 'common']);
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const dispatch: Dispatch<any> = useDispatch();

  const open = Boolean(anchorEl);
  const id = open ? 'popper-event' : undefined;

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : e.currentTarget);
  };

  const handleClickEdit = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : e.currentTarget);
    history.push(`${routeEvents}/edit/${event.id}`);
  };

  const handleClickPush = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : e.currentTarget);
    history.push(`${routeEvents}/push/${event.id}`);
  };

  const handleClickDelete = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : e.currentTarget);
    handleClickOpenModal(event.id);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  const getNameCategory = (categoryId: string) => {
    const category = categories
      .find((c) => c.id === categoryId);
    return category ? category.name : categoryId;
  };

  return (
    <Grid item xs={12}>
      <Card className={classes.card}>

        <IconButton
          className={classes.icon}
          onClick={handleClick}
        >
          <MoreVerticalOutline />
        </IconButton>

        <CardMedia
          className={classes.cover}
          image={event.pictures[0]?.file_path || image}
          title={event.title}
        />

        <div className={classes.details}>
          <CardContent className={classes.content}>
            {event.event_categories.map((categoryId: string) => (
              <Typography variant="subtitle2" className={classes.tag} key={categoryId}>
                {getNameCategory(categoryId)}
              </Typography>
            ))}

            <Typography variant="subtitle2" className={classes.date}>
              {format(new Date(event.start_date), 'cccc d MMMM yyyy', { locale: fr })}
            </Typography>
            <Typography variant="subtitle1">
              {event.title}
            </Typography>
            <Typography variant="body1" className={classes.description} component="span">
              <div
                style={{ wordBreak: 'break-all', wordWrap: 'break-word' }}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: htmlToString(event.description) }}
              />
            </Typography>
          </CardContent>
        </div>

        <Popper id={id} open={open} anchorEl={anchorEl} style={{ zIndex: 2 }} transition>
          {({ TransitionProps }) => (
            <ClickAwayListener onClickAway={handleClickAway}>
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <Fade {...TransitionProps} timeout={350}>
                <Paper elevation={1}>
                  <MenuList>
                    <MenuItem onClick={handleClickPush} className={classes.buttonEdit}>
                      {t('event.push')}
                    </MenuItem>
                    <MenuItem onClick={handleClickEdit} className={classes.buttonEdit}>
                      {t('common.edit')}
                    </MenuItem>
                    <MenuItem onClick={handleClickDelete} className={classes.buttonDelete}>
                      {t('common.delete')}
                    </MenuItem>
                  </MenuList>
                </Paper>
              </Fade>
            </ClickAwayListener>
          )}
        </Popper>
      </Card>
    </Grid>
  );
};

export default EventItem;
