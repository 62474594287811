import React, {
  Dispatch, FormEvent, useState, useEffect, useContext,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Typography, Grid, CircularProgress, Button,
} from '@material-ui/core';
import { DefaultRootState, useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import useStyles from '../../../components/paper/MainPaper.style';
import WhitePaper from '../../../components/paper/WhitePaper';
import GreyTextInput from '../../../components/form/GreyTextInput';
import UploadFileButton from '../../../components/form/UploadFileButton';
import {
  putProduct,
  postProduct,
  getProductCategories,
  resetStatus,
  getProductsAndProductCategories,
} from '../../../store/product/actions';
import { IProduct, ProductState } from '../../../store/product/types.d';
import { routeProducts } from '../../../constants/routes';
import Breadcrumb from '../../../components/common/Breadcrumb';
import { NotificationContext } from '../../../contexts/NotificationContext';
import SelectInput from '../../../components/form/SelectInput';
import getFileName from '../../../utils/getFileName';

type Props = {};

type Picture = {
  file?: File | string,
  preview: string,
  fileName: string
}

const FormProductPage: React.FC<Props> = () => {
  const { t } = useTranslation(['backoffice', 'common']);
  const classes = useStyles();
  const history = useHistory();
  const dispatch: Dispatch<any> = useDispatch();
  const slug: { id: string } = useParams();
  const { setNotification } = useContext(NotificationContext);

  const {
    sending,
    products,
    error,
    categories,
    status,
  } = useSelector<DefaultRootState, ProductState>(
    (state: any) => state.productStore,
  );

  useEffect(() => {
    if (products.length === 0) dispatch(getProductsAndProductCategories());
  }, []);

  const productId = slug.id;
  let product: IProduct | undefined;

  if (productId) {
    product = products.find((p: IProduct) => p.id === productId);
  }
  const getValuesFromProduct = (from: IProduct | undefined) => ({
    title: from?.title ?? '',
    link: from?.link ?? '',
    boxing: from?.boxing ?? '',
    product_category_id: from?.product_category_id ?? categories[0]?.id ?? '',
  });

  const [values, setValues] = useState<Pick<IProduct, 'title' | 'link' | 'boxing' | 'product_category_id'>>(
    getValuesFromProduct(product),
  );

  const [formErrors, setFormErrors] = useState({
    title: '',
    link: '',
    product_category_id: '',
  });

  const getPictureFromProduct = (from: IProduct | undefined) => ({
    file: from?.file_path ?? undefined,
    preview: from?.file_path ?? '',
    fileName: getFileName(from?.file_path) ?? '',
  });

  const [picture, setPicture] = useState<Picture>(getPictureFromProduct(product));

  useEffect(() => {
    product = products.find((p: IProduct) => p.id === productId);
    setValues(getValuesFromProduct(product));
    setPicture(getPictureFromProduct(product));
  }, [products]);

  const handleFormAction = (event: FormEvent) => {
    event.preventDefault();
    event.stopPropagation();
    let formError = false;
    const content = values;

    if (content.title === '') {
      formErrors.title = t('product.form.titleRequired');
      formError = true;
    }

    if (content.link === '') {
      formErrors.link = t('product.form.linkRequired');
      formError = true;
    }

    if (content.product_category_id === '') {
      formErrors.product_category_id = t('product.form.productCategoryIdRequired');
      formError = true;
    }

    setFormErrors({ ...formErrors });

    if (!formError) {
      if (productId) {
        dispatch(putProduct(productId, content, picture.file));
      } else {
        dispatch(postProduct(content, picture.file as File));
      }
    }
  };

  const handleChange = (prop: keyof Partial<IProduct>, value: any) => {
    setValues({ ...values, [prop]: value });
    setFormErrors({ ...formErrors, [prop]: '' });
  };

  const uploadFile = (file: File) => {
    if (file) {
      setPicture({ file, preview: URL.createObjectURL(file), fileName: file.name });
    }
  };

  useEffect(() => {
    dispatch(getProductCategories());
  }, []);

  useEffect(() => {
    if (status === 'ERROR') {
      setNotification({ isOpened: true, severity: 'error', message: error || '' });
    }

    if (status === 'SUCCESS') {
      setNotification({ isOpened: true, severity: 'success', message: t('product.form.success') });
      history.push(routeProducts);
    }
    if (status !== 'INIT') dispatch(resetStatus());
  }, [status]);

  return (
    <>
      <Breadcrumb
        title={t('title.products')}
        baseUrl={routeProducts}
        link={product && product.title}
      />
      <Typography variant="h1" className={classes.title}>
        {product && product.title}
      </Typography>
      <form onSubmit={handleFormAction}>
        <Grid container spacing={3}>
          <Grid item id="form-grid-item" xs={12} md={9}>
            <WhitePaper title={t('product.form.subtitle')}>
              <GreyTextInput
                type="text"
                value={values.title}
                label={t('product.form.title')}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange('title', event.target.value)}
                error={formErrors.title !== ''}
                helperText={formErrors.title}
              />
              <GreyTextInput
                type="text"
                value={values.boxing}
                label={t('product.form.boxing')}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange('boxing', event.target.value)}
              />
              <GreyTextInput
                type="text"
                value={values.link}
                label={t('product.form.link')}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange('link', event.target.value)}
                error={formErrors.link !== ''}
                helperText={formErrors.link}
              />
              <SelectInput
                values={categories}
                label={t('product.form.category')}
                valueSelected={values.product_category_id}
                onChange={(event: any) => handleChange('product_category_id', event.target.value)}
                error={formErrors.product_category_id !== ''}
                helperText={formErrors.product_category_id}
              />
            </WhitePaper>
            <br />
            <WhitePaper title={t('product.form.subtitlePicture')}>
              {picture.preview && (
              <img
                src={picture.preview}
                alt={picture.fileName}
                width="auto"
                height={115}
                className={classes.preview}
              />
              )}
              <UploadFileButton
                uploadFile={uploadFile}
                label={t('common.addPicture')}
              />
            </WhitePaper>
          </Grid>
          <Grid item id="validation-grid-item" xs={12} md={3}>
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              disabled={sending}
            >
              <Typography variant="button">
                {t('common.save')}
              </Typography>
              {
                  sending
                  && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                      color="primary"
                    />
                  )
                }
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default FormProductPage;
