import React, {
  Dispatch,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import {
  useHistory,
  useParams,
} from 'react-router';
import { format } from 'date-fns';
import {
  DefaultRootState,
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  eventCategories,
  EventState,
  IEvent,
} from '../../../store/event/types';
import {
  INotification,
  NotificationState,
} from '../../../store/notification/types';
import { RegionState } from '../../../store/region/types';
import {
  ITerritory,
  TerritoryState,
} from '../../../store/territory/types';
import { getEventsAndCategories } from '../../../store/event/actions';
import { getRegions } from '../../../store/region/actions';
import { getTerritories } from '../../../store/territory/actions';
import {
  getNotifications,
  getNotificationUsers,
  postNotification,
  postNotificationUsers,
  putNotification,
  resetAffectedUsersStatus,
  resetStatus,
} from '../../../store/notification/actions';
import Breadcrumb from '../../../components/common/Breadcrumb';
import {
  routeEvents,
  routeNotification,
} from '../../../constants/routes';
import useStyles from '../../../components/paper/MainPaper.style';
import GreyTextInput from '../../../components/form/GreyTextInput';
import WhitePaper from '../../../components/paper/WhitePaper';
import { NotificationContext } from '../../../contexts/NotificationContext';
import SaveIcon from '../../../components/icon/SaveIcon';
import FileIcon from '../../../components/icon/FileIcon';
import NavigationFillIcon from '../../../components/icon/NavigationFillIcon';
import { nuance100 } from '../../../constants/colors';
import AlertCircleFillIcon from '../../../components/icon/AlertCircleFillIcon';
import DatePicker from '../../../components/form/DatePicker';
import AutocompleteInput from '../../../components/form/AutocompleteInput';
import SelectInput from '../../../components/form/SelectInput';
import { futureOrCurrentEventsFilteredSelector } from '../../../store/event/selectors';
import NotificationUserModal from './NotificationUserModal';
import formatDateWithTimezone from '../../../utils/formatDate';
import {
  ISpeciality,
  SPECIALITIES,
} from '../../../constants/specialities';
import { DOCTOR_TYPES } from '../../../constants/doctorTypes';
import UploadFileButton from '../../../components/form/UploadFileButton';
import {
  getUsersFromCsv,
  getUsersFromExcel,
  IImportUser,
} from './FormNotificationPage.func';
import {
  CRITERIA,
  IMPORT,
} from '../../../constants/notification';
import { ExpertCourseState } from '../../../store/expert-course/types';
import { getExpertCourses } from '../../../store/expert-course/actions';
import SwitchComponent from '../../../components/form/SwitchComponent';

type Props = {};
type FileTool = {
  file: File | null,
  fileName: string
}

interface IValues {
  status: 'draft' | 'now' | 'scheduled' | 'sent' | 'error'
  // eslint-disable-next-line camelcase
  send_date: Date | null
  title: string
  description: string
  // eslint-disable-next-line camelcase
  user_count: number
  territories: ITerritory[],
  // eslint-disable-next-line camelcase
  event_categories: eventCategories[],
  events: IEvent[],
  specialities: ISpeciality[],
  // eslint-disable-next-line camelcase
  doctor_type: string,
  // eslint-disable-next-line camelcase
  criteria_type: string,
  // eslint-disable-next-line camelcase
  expert_courses: string[],
  // eslint-disable-next-line camelcase
  limit_to_event_subscriptions: boolean
}

const FormNotificationPage: React.FC<Props> = () => {
  const { t } = useTranslation(['backoffice', 'common']);
  const slug: { id: string } = useParams();
  const dispatch: Dispatch<any> = useDispatch();
  const history = useHistory();
  const { setNotification } = useContext(NotificationContext);
  const classes = useStyles();
  const {
    notifications,
    error,
    status,
    sending,
    affectedUsers,
    affectedUsersStatus,
    affectedUsersPending,
  } = useSelector<DefaultRootState, NotificationState>(
    (state: any) => state.notificationStore,
  );
  const eventStore: EventState = useSelector<DefaultRootState, EventState>(
    (state: any) => state.eventStore,
  );

  const regionStore = useSelector<DefaultRootState, RegionState>(
    (state: any) => state.regionStore,
  );

  const expertCoursesStore = useSelector<DefaultRootState, ExpertCourseState>(
    (state: any) => state.expertCoursesStore,
  );

  const territoryStore = useSelector<DefaultRootState, TerritoryState>(
    (state: any) => state.territoryStore,
  );

  const notificationId = slug.id;
  let notification: INotification | undefined;
  if (notificationId) {
    notification = notifications.find(
      (n: INotification) => n.id === notificationId,
    );
  }
  const futureEventsFiltered = useSelector(futureOrCurrentEventsFilteredSelector);
  const getFormValuesFromNotification = (): IValues => ({
    title: notification?.title ?? '',
    status: notification?.status ?? 'draft',
    send_date: notification?.send_date ? new Date(notification.send_date) : new Date(),
    description: notification?.description ?? '',
    user_count: notification?.user_count ?? 0,
    territories: notification?.territories ?? [],
    event_categories: notification?.event_categories ?? [],
    events: notification?.events ?? [],
    specialities: SPECIALITIES.filter((spe) => (notification?.specialities ?? []).includes(spe.id)),
    doctor_type: notification?.doctor_type ?? DOCTOR_TYPES[0],
    criteria_type: notification?.criteria_type ?? CRITERIA,
    expert_courses: notification?.expert_courses ?? [],
    limit_to_event_subscriptions: notification?.limit_to_event_subscriptions ?? false,
  });
  const getTabValueFromNotification = (): number => (
    notification && notification.criteria_type === IMPORT ? 1 : 0
  );

  const [file, setFile] = useState<FileTool>({ file: null, fileName: '' });
  const [importUsers, setImportUsers] = useState<IImportUser[]>([]);
  const [tabValue, setTabValue] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const [values, setValues] = useState(getFormValuesFromNotification());
  const [fileLoading, setfileLoading] = useState<boolean>(false);
  const [availableEvents, setAvailableEvents] = useState<IEvent[]>([]);
  const [formErrors, setFormErrors] = useState({
    title: '',
    status: '',
    send_date: '',
    description: '',
    file: '',
  });

  useEffect(() => {
    setAvailableEvents(futureEventsFiltered);
  }, [futureEventsFiltered]);

  useEffect(() => {
    setAvailableEvents(futureEventsFiltered.filter((e: IEvent) => {
      if (values.event_categories.length === 0) {
        return true;
      }
      let includes = false;
      values.event_categories.forEach((ec: eventCategories) => {
        if (includes) {
          return;
        }
        if (e.event_categories.includes(ec.id)) {
          includes = true;
        }
      });
      return includes;
    }));
    setValues({ ...values, events: [] });
  }, [values.event_categories]);

  useEffect(() => {
    if (tabValue === 1) {
      setValues({ ...values, criteria_type: IMPORT });
    } else {
      setValues({ ...values, criteria_type: CRITERIA });
    }
  }, [tabValue]);

  useEffect(() => {
    if (eventStore.events.length === 0) {
      dispatch(getEventsAndCategories());
    }
    if (regionStore.regions.length === 0) {
      dispatch(getRegions());
    }
    if (expertCoursesStore.expert_courses.length === 0) {
      dispatch(getExpertCourses());
    }
    if (territoryStore.territories.length === 0) {
      dispatch(getTerritories());
    }
    if (notifications.length === 0) {
      dispatch(getNotifications());
    }
    if (notificationId) {
      dispatch(getNotificationUsers(notificationId));
    }
  }, []);

  useEffect(() => {
    setValues(getFormValuesFromNotification());
    setTabValue(getTabValueFromNotification());
  }, [notifications]);

  useEffect(() => {
    if (status === 'ERROR') {
      setNotification({ isOpened: true, severity: 'error', message: error || '' });
    }
    if (status === 'SUCCESS') {
      setNotification({ isOpened: true, severity: 'success', message: t('event.form.success') });
      history.push(routeNotification);
    }
    if (status !== 'INIT') dispatch(resetStatus());
  }, [status]);

  useEffect(() => {
    if (affectedUsersStatus === 'ERROR') {
      setNotification({ isOpened: true, severity: 'error', message: error || '' });
    }
    if (affectedUsersStatus !== 'INIT') dispatch(resetAffectedUsersStatus());
  }, [affectedUsersStatus]);

  const endLoadingUser = (data: IImportUser[]) => {
    setfileLoading(false);
    if (data.length > 0) {
      setImportUsers(data);
    } else {
      setNotification(
        {
          isOpened: true,
          severity: 'error',
          message: t('notification.form.error.noFileData'),
        },
      );
    }
  };

  const endLoadingUserError = (importError: Error) => {
    setfileLoading(false);
    // eslint-disable-next-line no-console
    console.log(importError.message);
    setNotification(
      {
        isOpened: true,
        severity: 'error',
        message: t('notification.form.error.readFileError'),
      },
    );
  };

  useEffect(() => {
    if (file && file.file) {
      if (file.fileName.includes('.csv')) {
        setfileLoading(true);
        getUsersFromCsv(file.file)
          .then((data) => {
            endLoadingUser(data);
          })
          .catch((importError: Error) => {
            endLoadingUserError(importError);
          });
      } else if (
        file.fileName.includes('.xlsx')
        || file.fileName.includes('.xls')
        || file.fileName.includes('.ods')
      ) {
        setfileLoading(true);
        getUsersFromExcel(file.file)
          .then((data) => {
            endLoadingUser(data);
          })
          .catch((importError: Error) => {
            endLoadingUserError(importError);
          });
      } else {
        setNotification(
          { isOpened: true, severity: 'error', message: t('notification.form.error.wrongFile') },
        );
      }
    }
  }, [file]);

  useEffect(() => {
    if (
      values.status !== 'sent'
      && values.status !== 'error'
      && (values.criteria_type === CRITERIA || file?.file !== null)
    ) {
      const content = {
        territories_ids: values.territories.map((value: ITerritory) => value.id),
        event_category_ids: values.event_categories.map((value: eventCategories) => value.id),
        event_ids: values.events.map((event: IEvent) => event.id),
        specialities: values.specialities.map((spe) => spe.id),
        doctor_type: values.doctor_type ?? DOCTOR_TYPES[0],
        criteria_type: values.criteria_type,
        expert_courses: values.expert_courses,
        limit_to_event_subscriptions: values.limit_to_event_subscriptions,
        file_users: importUsers,
      };
      dispatch(postNotificationUsers(content));
    } else if (notification && values.criteria_type === IMPORT) {
      dispatch(getNotificationUsers(notification.id));
    }
  }, [
    notifications,
    notification,
    values.doctor_type,
    values.territories,
    values.specialities,
    values.event_categories,
    values.events,
    values.expert_courses,
    values.limit_to_event_subscriptions,
    importUsers,
  ]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (prop: keyof Partial<INotification>, value: any) => {
    if (prop === 'status' && value === 'draft') {
      setValues({ ...values, [prop]: value, send_date: null });
    } else {
      setValues({ ...values, [prop]: value });
    }
    setFormErrors({ ...formErrors, [prop]: '' });
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    setValues({ ...values, status: (event.target as HTMLInputElement).value });
  };

  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  const handleFormAction = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    let formError = false;
    if (values.title === '') {
      formErrors.title = t('notification.form.error.titleRequired');
      formError = true;
    }
    if (values.title.length > 65) {
      formErrors.title = t('notification.form.error.titleMaxLength');
      formError = true;
    }
    if (values.status === 'scheduled') {
      try {
        if (values.send_date === undefined || values.send_date === null) {
          formErrors.send_date = t('notification.form.error.dateRequired');
          formError = true;
        } else if (Number.isNaN(Date.parse(values.send_date.toDateString()))) {
          formErrors.send_date = t('notification.form.error.dateInvalid');
          formError = true;
        } else if (values.send_date < new Date()) {
          formErrors.send_date = t('notification.form.error.dateInPast');
          formError = true;
        }
      } catch (parseError: any) {
        formErrors.send_date = t('notification.form.error.dateInvalid');
        formError = true;
      }
    }
    setFormErrors({ ...formErrors });
    if (!formError) {
      const content = {
        title: values.title,
        description: values.description,
        status: values.status,
        send_date: formatDateWithTimezone(
          values.status === 'now' || values.send_date === null
            ? new Date()
            : values.send_date,
        ),
        territories_ids: values.territories.map((value: ITerritory) => value.id),
        event_category_ids: values.event_categories.map((value: eventCategories) => value.id),
        event_ids: values.events.map((event: IEvent) => event.id),
        specialities: values.specialities.map((spe) => spe.id),
        doctor_type: values.doctor_type,
        criteria_type: values.criteria_type,
        expert_courses: values.expert_courses,
        limit_to_event_subscriptions: values.limit_to_event_subscriptions,
        file_users: importUsers,
      };
      if (notificationId) {
        dispatch(putNotification(notificationId, content));
      } else {
        dispatch(postNotification(content));
      }
    }
  };

  const uploadFile = (fileUploaded: File) => {
    if (file) {
      setFile({ file: fileUploaded, fileName: fileUploaded.name });
      setFormErrors({ ...formErrors, file: '' });
    }
  };

  const getDateString = (date: any, dateOny?: boolean): string => {
    try {
      let dateFormat = 'dd/MM/yyyy HH:mm';
      if (dateOny) {
        dateFormat = 'dd/MM/yyyy';
      }
      return format(date, dateFormat);
    } catch (formatError: any) {
      return ' - ';
    }
  };

  return (
    <>
      <Breadcrumb
        title={t('title.notifications')}
        baseUrl={routeEvents}
        link={notification && notification.title}
      />
      <Typography variant="h1" className={classes.title}>
        {notification && notification.title}
      </Typography>
      <form onSubmit={handleFormAction}>
        <Grid container spacing={4}>

          <Grid item xs={9}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <WhitePaper title={t('notification.form.subtitle.users')}>
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <Tabs
                        value={tabValue}
                        onChange={handleChangeTab}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                      >
                        <Tab label={t('notification.form.tabs.criteria')} />
                        <Tab label={t('notification.form.tabs.import')} />
                      </Tabs>
                    </Grid>
                    {tabValue === 0 && (
                      <>
                        <Grid item xs={12} md={6}>
                          <SelectInput
                            values={DOCTOR_TYPES.map(
                              (item: string) => ({
                                id: item, title: t(`notification.form.doctorTypes.${item}`),
                              }),
                            )}
                            label={t('notification.form.input.doctorType')}
                            onChange={(event: any) => handleChange('doctor_type', event.target.value)}
                            valueSelected={values.doctor_type}
                          />
                          <AutocompleteInput
                            options={SPECIALITIES}
                            onChange={(e: object, value: ISpeciality[]) => handleChange('specialities', value)}
                            label={t('notification.form.input.specialities')}
                            value={values.specialities}
                          />
                          <AutocompleteInput
                            options={territoryStore.territories}
                            onChange={(e: object, value: ITerritory[]) => handleChange('territories', value)}
                            label={t('notification.form.input.territories')}
                            value={values.territories}
                            displayId
                          />
                          <AutocompleteInput
                            options={expertCoursesStore.expert_courses}
                            onChange={(e: object, value: string[]) => handleChange('expert_courses', value)}
                            label={t('notification.form.input.expertCourses')}
                            value={values.expert_courses}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <AutocompleteInput
                            options={eventStore.categories}
                            label={t('notification.form.input.eventCategories')}
                            onChange={(e: object, value: eventCategories[]) => handleChange('event_categories', value)}
                            value={values.event_categories}
                          />
                          <AutocompleteInput
                            options={availableEvents}
                            label={t('notification.form.input.event')}
                            onChange={(e: object, value: IEvent[]) => handleChange('events', value)}
                            value={values.events}
                            disabled={availableEvents.length === 0}
                            helperText={availableEvents.length === 0 ? t('notification.form.input.noEvent') : ''}
                          />
                          {values.events.length > 0 && (
                            <SwitchComponent
                              value={values.limit_to_event_subscriptions}
                              onChange={(e: any) => handleChange('limit_to_event_subscriptions', e.target.checked)}
                              label={t('notification.form.input.event_limited_to_subscribed')}
                              name="limit_to_event_subscriptions"
                            />
                          )}
                          <Typography variant="body1">
                            {t('notification.form.eventSelectInformation')}
                          </Typography>
                        </Grid>
                      </>
                    )}

                    {tabValue === 1 && (
                      <>
                        <Grid item xs={12} md={6}>
                          <UploadFileButton
                            uploadFile={uploadFile}
                            error={formErrors.file !== ''}
                            helperText={formErrors.file}
                            label={t('common.addFile')}
                            accept={[
                              'text/csv',
                              'application/*',
                            ].join(',')}
                          />
                          {fileLoading && (
                            <Typography variant="body1" component="span">
                              {t('common.loading')}
                            </Typography>
                          )}
                          {!fileLoading && importUsers.length > 0 && (
                            <>
                              <Typography variant="h3" color="primary" component="span">
                                {importUsers.length}
                              </Typography>
                              {' '}
                              <Typography variant="body1" component="span">
                                {t('notification.form.importFound')}
                              </Typography>
                            </>
                          )}
                        </Grid>
                      </>
                    )}
                  </Grid>
                </WhitePaper>
              </Grid>
              <Grid item xs={12}>
                <WhitePaper title={t('notification.form.subtitle.content')}>
                  <GreyTextInput
                    type="text"
                    value={values.title}
                    label={t('notification.form.input.title')}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange('title', e.target.value)}
                    error={formErrors.title !== '' || values.title.length > 65}
                    disabled={values.status === 'sent' || values.status === 'error'}
                    helperText={values.status !== 'sent'
                      ? (
                        formErrors.title
                        || t(
                          'notification.form.helperText.maxLength',
                          { max: 65, rest: Math.max(0, 65 - values.title.length) },
                        )
                      )
                      : ''}
                  />
                  <GreyTextInput
                    type="text"
                    value={values.description}
                    label={t('notification.form.input.description')}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange('description', e.target.value)}
                    error={formErrors.description !== '' || values.description.length > 170}
                    disabled={values.status === 'sent' || values.status === 'error'}
                    helperText={values.status !== 'sent'
                      ? (
                        formErrors.description
                        || t(
                          'notification.form.helperText.maxLength',
                          { max: 170, rest: Math.max(0, 170 - values.description.length) },
                        )
                      )
                      : ''}
                  />
                </WhitePaper>
              </Grid>
            </Grid>
          </Grid>
          <Grid id="validation-grid-item" item xs={3}>
            <Grid container id="validation-grid-item" spacing={4}>
              <Grid item xs={12}>
                <WhitePaper
                  title=""
                  loading={
                    notification?.status !== 'sent'
                    && notification?.status !== 'error'
                    && affectedUsersPending
                  }
                >
                  <div>
                    <Typography variant="h3" color="primary" component="span">
                      {
                        notification?.status !== 'sent'
                        && notification?.status !== 'error'
                        && (
                          values?.criteria_type === CRITERIA
                          || (values?.criteria_type === IMPORT && file.file !== null)
                        )
                          ? affectedUsers.length
                          : notification?.user_count ?? '-'
                      }
                    </Typography>
                    {' '}
                    <Typography variant="body1" component="span">
                      {t('notification.form.affectedUsers')}
                    </Typography>
                  </div>
                  <Button
                    variant="outlined"
                    color="primary"
                    className={classes.buttonSeeList}
                    onClick={handleOpen}
                  >
                    <Typography variant="button">
                      {t('event.form.subscriptionButton')}
                    </Typography>
                  </Button>
                </WhitePaper>
              </Grid>
              {values.status !== 'error' && values.status !== 'sent' && (
                <Grid item xs={12}>
                  <WhitePaper title={t('notification.form.subtitle.sendDate')}>
                    <FormControl
                      component="fieldset"
                      className={classes.fullWidth}
                    >
                      <RadioGroup
                        aria-label="status"
                        name="status"
                        value={values.status}
                        onChange={handleRadioChange}
                        className={classes.fullWidth}
                      >
                        <FormControlLabel
                          value="now"
                          control={<Radio color="primary" />}
                          label={t('notification.form.input.now')}
                        />
                        <FormControlLabel
                          value="scheduled"
                          control={<Radio color="primary" />}
                          label={t('notification.form.input.scheduled')}
                        />
                        {values.status === 'scheduled' && (
                          <DatePicker
                            value={values.send_date}
                            label={t('notification.form.input.sendDate')}
                            onChange={(date: MaterialUiPickersDate) => handleChange('send_date', date)}
                            error={formErrors.send_date !== ''}
                            helperText={formErrors.send_date}
                          />
                        )}
                        <FormControlLabel
                          value="draft"
                          control={<Radio color="primary" />}
                          label={t('notification.form.input.draft')}
                        />
                        {values.status === 'draft' && (
                          <Typography variant="body1">
                            {t('notification.form.draftMessage')}
                          </Typography>
                        )}
                      </RadioGroup>
                    </FormControl>
                  </WhitePaper>
                </Grid>
              )}
              <Grid item xs={12}>
                {values.status === 'draft' && (
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    startIcon={<FileIcon width={22} height={22} color={nuance100} />}
                    disabled={sending}
                    className={classes.buttonAction}
                  >
                    <Typography variant="button">
                      {t('notification.form.buttonDraft')}
                    </Typography>
                    {
                      sending
                      && (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                          color="primary"
                        />
                      )
                    }
                  </Button>
                )}
                {values.status === 'now' && (
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    startIcon={<NavigationFillIcon width={22} height={22} color={nuance100} />}
                    disabled={sending}
                    className={classes.buttonAction}
                  >
                    <Typography variant="button">
                      {t('notification.form.buttonNow')}
                    </Typography>
                    {
                      sending
                      && (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                          color="primary"
                        />
                      )
                    }
                  </Button>
                )}
                {values.status === 'scheduled' && (
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    startIcon={<SaveIcon width={22} height={22} />}
                    disabled={sending}
                    className={classes.buttonAction}
                  >
                    <Typography variant="button">
                      {t(
                        'notification.form.buttonScheduled',
                        { date: getDateString(values.send_date, true) },
                      )}
                    </Typography>
                    {
                      sending
                      && (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                          color="primary"
                        />
                      )
                    }
                  </Button>
                )}
                {values.status === 'sent' && (
                  <Button
                    variant="contained"
                    color="secondary"
                    type="button"
                    startIcon={<NavigationFillIcon width={22} height={22} color={nuance100} />}
                    disabled
                    className={classes.buttonAction}
                  >
                    <Typography variant="button">
                      {t(
                        'notification.form.buttonSent',
                        { date: getDateString(values.send_date, true) },
                      )}
                    </Typography>
                  </Button>
                )}
                {values.status === 'error' && (
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    startIcon={<AlertCircleFillIcon width={22} height={22} />}
                    disabled
                    className={classes.buttonAction}
                  >
                    <Typography variant="button">
                      {t('notification.form.buttonError')}
                    </Typography>
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
      <NotificationUserModal handleClose={handleClose} open={open} />
    </>
  );
};

export default FormNotificationPage;
