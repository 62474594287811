import React, { Dispatch } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Popper,
  ClickAwayListener,
  Fade,
  MenuList,
  MenuItem,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getYear } from 'date-fns';
import useStyles from '../../flash-info/FlashInfoTable.style';
import MoreVerticalOutline from '../../../../components/icon/MoreVerticalOutline';
import { IUser } from '../../../../store/user/types';
import { deleteUser, putUser } from '../../../../store/user/actions';
import UserModalDelete from '../UserModalDelete';

type Props = {
  users: IUser[],
  isModerate?: boolean,
  isDisabled?: boolean,
  isActive?: boolean
}

const UsersOphthaTable = ({
  users, isModerate, isDisabled, isActive,
}: Props) => {
  const { t } = useTranslation(['backoffice']);
  const classes = useStyles();

  const dispatch: Dispatch<any> = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [userSelected, setUserSelected] = React.useState<IUser | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'popper-flashInfo' : undefined;
  const [openModal, setOpenModal] = React.useState<boolean>(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>, user: IUser) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setUserSelected(user);
  };

  const handleClickDisabled = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    if (userSelected) {
      dispatch(putUser(userSelected.id, { is_activated: false }));
    }
  };

  const handleClickRefused = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    if (userSelected) {
      dispatch(putUser(userSelected.id, { is_activated: false }));
    }
  };

  const handleClickAuthorized = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    if (userSelected) {
      dispatch(putUser(userSelected.id, { is_activated: true }));
    }
  };

  const handleClickOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleDeleteEvent = () => {
    if (userSelected) {
      dispatch(deleteUser(userSelected.id));
      setOpenModal(false);
    }
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  return (
    <TableContainer component={Paper} style={{ padding: '30px' }}>
      <Table aria-label="users table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.cell}>
              {t('users.table.youngOphtha')}
            </TableCell>
            <TableCell className={classes.cell}>
              {t('users.table.lastName')}
            </TableCell>
            <TableCell className={classes.cell}>
              {t('users.table.firstName')}
            </TableCell>
            <TableCell className={classes.cell}>
              {t('users.table.rpps')}
            </TableCell>
            <TableCell className={classes.cell}>
              {t('users.table.email')}
            </TableCell>
            <TableCell className={classes.cell}>
              {t('users.table.phone')}
            </TableCell>
            <TableCell align="right" />
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user) => (
            <TableRow key={user.id}>
              <TableCell component="th" scope="row">
                {user.is_boarder && (getYear(new Date()) - user.boarder_start_year) <= 10 ? t('users.table.young') : t('users.table.notYoung')}
              </TableCell>
              <TableCell component="th" scope="row">
                {user.last_name}
              </TableCell>
              <TableCell component="th" scope="row">
                {user.first_name}
              </TableCell>
              <TableCell component="th" scope="row">
                {user.rpps}
              </TableCell>
              <TableCell component="th" scope="row">
                {user.email}
              </TableCell>
              <TableCell component="th" scope="row">
                {user.phone}
              </TableCell>
              <TableCell align="right">
                <IconButton
                  onClick={(e) => handleClick(e, user)}
                >
                  <MoreVerticalOutline />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
          <Popper
            id={id}
            open={open}
            anchorEl={anchorEl}
            style={{ zIndex: 2 }}
            transition
          >
            {({ TransitionProps }) => (
              <ClickAwayListener onClickAway={handleClickAway}>
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <Fade {...TransitionProps} timeout={350}>
                  <Paper elevation={1}>
                    <MenuList>
                      {(isModerate || isDisabled) && (
                        <MenuItem
                          onClick={handleClickAuthorized}
                          className={classes.buttonEdit}
                        >
                          {t('common.authorized')}
                        </MenuItem>
                      )}
                      {(!isModerate && isDisabled) && (
                        <MenuItem
                          onClick={handleClickOpenModal}
                          className={classes.buttonDelete}
                        >
                          {t('common.delete')}
                        </MenuItem>
                      )}
                      {isActive && (
                        <MenuItem
                          onClick={handleClickDisabled}
                          className={classes.buttonDelete}
                        >
                          {t('common.disable')}
                        </MenuItem>
                      )}
                      {isModerate && (
                        <MenuItem
                          onClick={handleClickRefused}
                          className={classes.buttonDelete}
                        >
                          {t('common.reject')}
                        </MenuItem>
                      )}

                    </MenuList>
                  </Paper>
                </Fade>
              </ClickAwayListener>
            )}
          </Popper>
        </TableBody>
      </Table>
      {userSelected
        && (
          <UserModalDelete
            open={openModal}
            handleClose={handleCloseModal}
            handleValidate={handleDeleteEvent}
            email={userSelected.email}
          />
        )}
    </TableContainer>
  );
};

const defaultProps = {
  isActive: false,
  isDisabled: false,
  isModerate: false,
};

UsersOphthaTable.defaultProps = defaultProps;

export default React.memo(UsersOphthaTable);
